.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 2rem;
  background: white;
  border-bottom: 1px solid #e5e7eb;
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.nav-logo {
  height: 20px;
  cursor: pointer;
  transition: opacity 0.2s;
  padding: 4px;
}

.nav-logo:hover {
  opacity: 0.8;
}

.nav-right {
  display: flex;
  gap: 2rem;
  align-items: center;
  height: 100%;
}

.nav-right a {
  color: #6b7280;
  text-decoration: none;
  font-size: 13px;
  line-height: 40px;
}

.user-profile {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1rem;
}

.profile-trigger {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 4px;
}

.profile-trigger:hover {
  background-color: #f3f4f6;
}

.profile-picture {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  width: 240px;
  z-index: 50;
}

.dropdown-header {
  padding: 1rem;
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.dropdown-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.dropdown-user-info {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.dropdown-name {
  font-weight: 500;
  color: #111827;
  font-size: 14px;
}

.dropdown-email {
  color: #6b7280;
  font-size: 13px;
}

.dropdown-divider {
  height: 1px;
  background-color: #e5e7eb;
  margin: 0;
}

.dropdown-item {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  background: none;
  border: none;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f3f4f6;
}

.user-name {
  color: #374151;
  font-size: 13px;
  font-weight: 500;
} 