.product-page {
  min-height: 100vh;
  background-color: #f8f9fa;
}

.product-header {
  padding: 1.5rem 2rem;
  background: white;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-page {
    min-height: 100vh;
    background-color: #f8f9fa;
    padding-top: 40px; /* Add this to account for fixed navbar height */
  }
  
.header-content h1 {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 0.5rem 0;
}

.product-meta {
  color: #6b7280;
  font-size: 14px;
}

.header-actions {
  display: flex;
  gap: 1rem;
}

.share-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: white;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
}

.deploy-button {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 6px;
  background: #6366f1;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.product-content {
  display: flex;
  min-height: calc(100vh - 130px);
}

.sidebar {
  width: 240px;
  background: white;
  border-right: 1px solid #e5e7eb;
  padding: 1.5rem 0;
}

.side-nav {
  display: flex;
  flex-direction: column;
}

.nav-item {
  padding: 0.75rem 1.5rem;
  color: #6b7280;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.2s;
}

.nav-item:hover {
  background-color: #f3f4f6;
}

.nav-item.active {
  color: #6366f1;
  background-color: #eef2ff;
}

.environment-section {
  margin-top: 2rem;
  padding: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.environment-section h2 {
  font-size: 12px;
  font-weight: 500;
  color: #6b7280;
  margin: 0 0 1rem 0;
}

.environment-button {
  width: 100%;
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
}

.chevron-down {
  font-size: 10px;
  color: #6b7280;
}

.main-content {
  flex: 1;
  padding: 2rem;
} 