.landing-container {
  min-height: 100vh;
  background-color: #f8f9fa;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 2rem;
  background: white;
  border-bottom: 1px solid #e5e7eb;
}

.nav-left img {
  height: 48px;
}

.nav-right {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.landing-container {
    min-height: 100vh;
    padding-top: 40px; /* Add this to account for fixed navbar height */
  }
  
.nav-right a {
  color: #6b7280;
  text-decoration: none;
  font-size: 14px;
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  font-size: 32px;
  color: #111827;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #6b7280;
  margin-bottom: 2rem;
  font-size: 16px;
}

.prompt-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.prompt-input {
  flex: 1;
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 16px;
  background: white;
}

.start-button {
  padding: 0 2rem;
  background: #6366f1;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}

.sample-prompts {
  margin-bottom: 2rem;
}

.sample-prompts h2 {
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 1rem;
  font-weight: 500;
}

.prompt-chips {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.prompt-chip {
  padding: 0.5rem 1rem;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 20px;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.prompt-chip:hover {
  background-color: #f3f4f6;
}

.tabs {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e5e7eb;
}

.tab {
  padding: 1rem 0;
  color: #6b7280;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
}

.tab.active {
  color: #6366f1;
  border-bottom: 2px solid #6366f1;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.5rem 0;
}

.project-card {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.project-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-preview {
  background: #f3f4f6;
  border-radius: 8px;
  height: 140px;
  margin-bottom: 1rem;
}

.project-card h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #111827;
}

.project-card p {
  font-size: 14px;
  color: #6b7280;
}

.new-project-card {
  background: #f9fafb;
  border: 2px dashed #e5e7eb;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  height: 100%;
}

.new-project-card:hover {
  background: #f3f4f6;
}

.add-icon {
  font-size: 24px;
  color: #6b7280;
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
} 