.pricing-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding-top: 40px;
}

.pricing-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  text-align: center;
}

.pricing-content h1 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #111827;
}

.subtitle {
  font-size: 18px;
  color: #6b7280;
  margin-bottom: 3rem;
}

.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6366f1;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;
}

.pricing-card {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  text-align: left;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.popular {
  border: 2px solid #6366f1;
}

.popular-badge {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #6366f1;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.pricing-card h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.plan-description {
  color: #6b7280;
  margin-bottom: 2rem;
}

.price {
  margin-bottom: 2rem;
}

.currency {
  font-size: 24px;
  font-weight: 500;
  vertical-align: top;
}

.amount {
  font-size: 48px;
  font-weight: 600;
}

.period {
  color: #6b7280;
}

.custom-price {
  font-size: 32px;
  font-weight: 600;
}

.plan-button {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  font-weight: 500;
  margin-bottom: 2rem;
  cursor: pointer;
}

.plan-button.outline {
  background: white;
  border: 1px solid #6366f1;
  color: #6366f1;
}

.plan-button.solid {
  background: #6366f1;
  border: none;
  color: white;
}

.features-label {
  font-weight: 500;
  margin-bottom: 1rem;
}

.features ul {
  list-style: none;
  padding: 0;
}

.features li {
  color: #6b7280;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.features li::before {
  content: "•";
  color: #6366f1;
}

.faq-section {
  max-width: 768px;
  margin: 0 auto;
  text-align: left;
}

.faq-section h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 2rem;
}

.faq-item {
  border-bottom: 1px solid #e5e7eb;
  padding: 1.5rem 0;
}

.faq-item summary {
  font-weight: 500;
  cursor: pointer;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-item summary::after {
  content: "+";
  font-size: 20px;
  color: #6b7280;
}

.faq-item[open] summary::after {
  content: "-";
}

.faq-item p {
  margin-top: 1rem;
  color: #6b7280;
} 