.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 1rem;
}

.login-card {
  display: grid;
  grid-template-columns: 450px 1fr;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
}

.login-content {
  padding: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 450px;
}

h1 {
  font-size: 28px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 8px;
}

.subtitle {
  color: #6B7280;
  font-size: 16px;
  margin-bottom: 32px;
}

.signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.signin-button img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.signin-button:hover {
  background-color: #F9FAFB;
}

.login-footer {
  margin-top: auto;
  padding-top: 24px;
}

.terms {
  margin-bottom: 24px;
  font-size: 14px;
  color: #6B7280;
}

.terms a {
  color: #6366F1;
  text-decoration: none;
  margin: 0 4px;
}

.signup-prompt {
  font-size: 14px;
  color: #6B7280;
  margin: 0;
}

.signup-prompt a {
  color: #6366F1;
  text-decoration: none;
  margin-left: 4px;
}

/* Right side styling */
.feature-content {
  background: #F9FAFB;
  padding: 3rem;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.feature-content h2 {
  font-size: 36px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 48px;
  line-height: 1.2;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 16px;
}

.feature-icon {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  background: #6985de;
  border-radius: 50%;
}

.feature-text {
  flex: 1;
}

.feature-text h3 {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 4px 0;
  line-height: 1.4;
  text-align: left;
}

.feature-text p {
  color: #6B7280;
  font-size: 14px;
  margin: 0;
  line-height: 1.5;
}

.features {
  margin-left: 80px;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  padding-top: 24px;
  border-top: 1px solid #E5E7EB;
}

.stat-item {
  text-align: center;
}

.stat-item h4 {
  font-size: 24px;
  font-weight: 600;
  color: #6366F1;
  margin-bottom: 4px;
}

.stat-item p {
  color: #6B7280;
  font-size: 14px;
}

/* Logo styling */
.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo-image {
    width: 183px;
    height: 44px;
}

.logo-text {
  font-size: 18px;
  font-weight: 500;
  color: #111827;
}

.signin-button-wrapper {
  margin-bottom: 12px;
  width: 100%;
}

/* Style adjustments for Google button */
.signin-button-wrapper > div {
  width: 100% !important;
}

.signin-button-wrapper > div > div {
  width: 100% !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #E5E7EB !important;
}

.signin-button-wrapper > div > div:hover {
  background-color: #F9FAFB !important;
} 