.documentation-page {
  min-height: 100vh;
  background-color: white;
  padding-top: 40px;
}

.doc-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid #e5e7eb;
  background: white;
}

.main-nav {
  display: flex;
  gap: 2rem;
}

.nav-item {
  color: #6b7280;
  text-decoration: none;
  font-size: 14px;
  padding: 0.5rem 0;
}

.nav-item.active {
  color: #6366f1;
  border-bottom: 2px solid #6366f1;
}

.version-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  color: #374151;
  cursor: pointer;
}

.doc-content {
  display: flex;
  min-height: calc(100vh - 120px);
}

.doc-sidebar {
  width: 280px;
  border-right: 1px solid #e5e7eb;
  padding: 1.5rem;
}

.search-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  margin-bottom: 2rem;
}

.search-box input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}

.sidebar-section {
  margin-bottom: 2rem;
}

.sidebar-section h3 {
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 1rem;
  font-weight: 500;
}

.sidebar-item {
  display: block;
  padding: 0.5rem 0;
  color: #6b7280;
  text-decoration: none;
  font-size: 14px;
}

.sidebar-item.active {
  color: #6366f1;
  background-color: #eef2ff;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0 -0.5rem;
}

.main-content {
  flex: 1;
  padding: 2rem 4rem;
  max-width: 768px;
}

.main-content h1 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.subtitle {
  color: #6b7280;
  margin-bottom: 2rem;
}

.quick-links {
  margin-bottom: 3rem;
}

.quick-links-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.quick-links-grid a {
  color: #6366f1;
  text-decoration: none;
}

.code-block {
  background: #1e293b;
  padding: 1.5rem;
  border-radius: 8px;
  color: white;
  margin: 1.5rem 0;
}

.code-comment {
  color: #94a3b8;
  margin-bottom: 0.5rem;
}

.table-of-contents {
  width: 240px;
  padding: 2rem;
}

.toc-content {
  position: sticky;
  top: 2rem;
}

.toc-content h2 {
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 1rem;
  font-weight: 500;
}

.toc-content a {
  display: block;
  color: #6b7280;
  text-decoration: none;
  font-size: 14px;
  padding: 0.25rem 0;
}

.help-section {
  margin-top: 3rem;
}

.help-section h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.help-section a {
  display: block;
  color: #6b7280;
  text-decoration: none;
  font-size: 14px;
  padding: 0.25rem 0;
} 